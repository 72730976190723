import { createStitches, defaultThemeMap } from '@stitches/react'
import localFont from 'next/font/local'

export const cabinetGrotesk = localFont({
  src: '../../public/fonts/CabinetGrotesk-Variable.woff2',
  display: 'swap',
  variable: '--font-cabinet-grotesk',
})

export const nunitoSans = localFont({
  src: [
    {
      path: '../../public/fonts/NunitoSans-Regular.ttf',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../public/fonts/NunitoSans-SemiBold.ttf',
      weight: '600',
      style: 'normal',
    },
    {
      path: '../../public/fonts/NunitoSans-Bold.ttf',
      weight: '700',
      style: 'normal',
    },
  ],
  display: 'swap',
  variable: '--font-nunito-sans',
})

export const {
  config,
  styled,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  css,
} = createStitches({
  themeMap: {
    ...defaultThemeMap,
    height: 'space',
    width: 'space',
  },

  theme: {
    borderStyles: {},
    borderWidths: {
      xs: '1px',
      sm: '1.5px',
      md: '12px',
      lg: '2.5px',
      xl: '4px',
      '2xl': '8px',
    },
    colors: {
      primary50: '#fe2b8f',

      secondary10: '#490424',
      secondary30: '#aa0955',
      secondary40: '#da0b6d',
      secondary50: '#f20d7a',
      secondary70: '#fe67af',
      secondary80: '#ff9ac9',
      secondary90: '#ffcce4',
      secondary95: '#ffe0ef',
      secondary99: '#fff5fa',

      tertiary40: '#c8195a',

      neutral10: '#1d161b',
      neutral20: '#3b2e37',
      neutral30: '#53464f',
      neutral40: '#6b5b66',
      neutral50: '#83727d',
      neutral60: '#9f939b',
      neutral70: '#b9acb4',
      neutral80: '#d1c7ce',
      neutral90: '#ebe6e9',
      neutral95: '#f8f6f8',
      neutral100: '#ffffff',

      error10: '#4c2900',
      error40: '#c96c01',
      error50: '#fea034',
      error70: '#fec380',
      error90: '#ffecd6',

      info10: '#00244c',
      info40: '#015fcb',
      info50: '#4da0fe',
      info70: '#8ac1fe',
      info90: '#d4e8ff',

      success10: '#094338',
      success40: '#21a38a',
      success50: '#2ed9b8',
      success70: '#97ecdc',
      success90: '#d7f9f3',

      white: '#ffffff',
      black: '#000000',
    },
    fonts: {
      primary: `${cabinetGrotesk.style.fontFamily}, Verdana, sans-serif`,
      secondary: `${nunitoSans.style.fontFamily}, Verdana, sans-serif`,
    },
    fontSizes: {
      xxs: '0.625rem',
      xs: '0.75rem',
      sm: '0.875rem',
      base: '1rem',
      md: '1.25rem',
      lg: '1.5rem',
      xl: '2rem',
      '2xl': '2.5rem',
      '3xl': '3rem',
      '4xl': '3.5rem',
      '5xl': '4rem',
      '6xl': '4.5rem',
      '7xl': '5.5rem',
      '8xl': '6rem',
    },
    fontWeights: {
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      extrabold: 800,
    },
    lineHeights: {
      default: 1,
      xs: 1.12,
      sm: 1.16,
      md: 1.2,
      lg: 1.4,
      xl: 1.48,
    },
    radii: {
      xs: '4px',
      sm: '8px',
      md: '12px',
      lg: '16px',
      xl: '20px',
      '2xl': '24px',
      circular: '50%',
    },
    shadows: {
      neutral1: '0 4px 8px rgba(0, 0, 0, 0.08)',
      neutral2: '0 4px 24px rgba(0, 0, 0, 0.12)',
      neutral3: '0 8px 32px rgba(0, 0, 0, 0.16)',
      neutral4: '0 16px 40px rgba(0, 0, 0, 0.24)',

      primaryGlow1: '0 4px 8px rgba(254, 43, 143, 0.08)',
      primaryGlow2: '0 4px 24px rgba(254, 43, 143, 0.12)',
      primaryGlow3: '0 4px 32px rgba(254, 43, 143, 0.16)',
      primaryGlow4: '0 16px 40px rgba(254, 43, 143, 0.24)',

      successGlow1: '0 4px 8px rgba(38, 217, 182, 0.08)',
      successGlow2: '0 4px 24px rgba(38, 217, 182, 0.12)',
      successGlow3: '0 8px 32px rgba(38, 217, 182, 0.16)',
      successGlow4: '0 16px 40px rgba(38, 217, 182, 0.24)',

      errorGlow1: '0 4px 8px rgba(254, 160, 52, 0.08)',
      errorGlow2: '0 4px 24px rgba(254, 160, 52, 0.12)',
      errorGlow3: '0 8px 32px rgba(254, 160, 52, 0.16)',
      errorGlow4: '0 16px 40px rgba(254, 160, 52, 0.24)',

      infoGlow1: '0 4px 8px rgba(77, 160, 254, 0.08)',
      infoGlow2: '0 4px 24px rgba(77, 160, 254, 0.12)',
      infoGlow3: '0 8px 32px rgba(77, 160, 254, 0.16)',
      infoGlow4: '0 16px 40px rgba(77, 160, 254, 0.24)',
    },
    space: {
      1: '8px',
      2: '16px',
      3: '24px',
      4: '32px',
      5: '40px',
      6: '48px',
      7: '56px',
      8: '64px',
      9: '72px',
      10: '80px',
      12: '96px',
      13: '104px',
      14: '112px',
      15: '120px',
      18: '144px',
      20: '160px',
    },
  } as const,

  media: {
    xsm: '(min-width: 360px)',
    sm: '(min-width: 492px)',
    md: '(min-width: 768px)',
    lg: '(min-width: 1024px)',
    xl: '(min-width: 1337px)',
  },

  utils: {
    // Abbreviated margin properties
    m: (value: string) => ({
      margin: value,
    }),
    mt: (value: number | string) => ({
      marginTop: value,
    }),
    mr: (value: number | string) => ({
      marginRight: value,
    }),
    mb: (value: number | string) => ({
      marginBottom: value,
    }),
    ml: (value: number | string) => ({
      marginLeft: value,
    }),
    mx: (value: number | string) => ({
      marginLeft: value,
      marginRight: value,
    }),
    my: (value: number | string) => ({
      marginTop: value,
      marginBottom: value,
    }),
    p: (value: string) => ({
      padding: value,
    }),
    pt: (value: number | string) => ({
      paddingTop: value,
    }),
    pr: (value: number | string) => ({
      paddingRight: value,
    }),
    pb: (value: number | string) => ({
      paddingBottom: value,
    }),
    pl: (value: number | string) => ({
      paddingLeft: value,
    }),
    px: (value: number | string) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    py: (value: number | string) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
  },
})

export const buttonLoadingAnimation = keyframes({
  from: {
    backgroundPosition: '100%',
  },
  to: {
    backgroundPosition: '0%',
  },
})

export const commonLoadingStyles = {
  backgroundSize: '200%',
  pointerEvents: 'none',
  animationDuration: '2s',
  animationFillMode: 'forwards',
  animationIterationCount: 'infinite',
  animationName: `${buttonLoadingAnimation}`,
  animationTimingFunction: 'linear',
}
