import { ToastViewport } from '@/components/Toast/styles'
import * as Toast from '@radix-ui/react-toast'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import type { ReactElement, ReactNode } from 'react'

import { AppStateProvider } from '@/contexts/AppStateContext'
import { globalStyles } from '@/styles/global'

globalStyles()

export type NextPageWithLayout<
  P = NonNullable<Record<string, unknown>>,
  IP = P,
> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout || ((page) => page)
  return (
    <Toast.Provider
      swipeDirection="right"
      label="Notification"
      duration={10000}
    >
      <AppStateProvider>
        {getLayout(<Component {...pageProps} />)}
      </AppStateProvider>
      <ToastViewport />
      {/* <Script
        src={`https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}`}
        async
      />
      <Analytics /> */}
    </Toast.Provider>
  )
}
